import { createApp } from 'vue';
import App from './App.vue';
import store from "./store";
import router from './router';


import { createAuth0 } from '@auth0/auth0-vue';

import axios from 'axios'
import VueAxios from 'vue-axios'

// Global styles
import './assets/main.css'

import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";

const app = createApp(App);

app.use(
    createAuth0({
      domain: process.env.VUE_APP_AUTH0_DOMAIN,
      client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
      redirect_uri: window.location.origin,
      audience: process.env.VUE_APP_AUTH0_AUDIENCE,
      responseType: 'token id_token'
    })
  );

app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(ArgonDashboard);
app.mount('#app');
