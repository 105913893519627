<template>
  <div>
    <div>
      <ul>
        <li v-if="doculist.length">Object Length = {{ doculist.length }}</li>
        <li v-for='docu in doculist' v-bind:key='docu.id'>
         {{ docu }}
         {{ doculist.length }}
         {{ docu.formato}}
        </li>
      </ul>
    </div>

    <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th>Formato</th>
                    <th>id</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="docu in doculist" v-bind:key="docu.id">
                    <td>{{docu.formato}} </td>
                    <td>{{docu.id}}</td>
                    <td>{{docu.status}}</td>
                </tr>
            </tbody>
        </table>

  </div>
</template>

<script>
import { useAuth0 } from '@auth0/auth0-vue'
import { ref, onMounted} from 'vue'

export default {
  name: "DocuList",
  setup() {
        const { idTokenClaims } = useAuth0();
        const user_id_token = ref(idTokenClaims._rawValue.__raw);
        const error = ref(null);
        const doculist = ref([]);

        onMounted(async () => {
            try {
                console.log("trying to get docus");
                //const id_token = idTokenClaims._rawValue.__raw;
                console.log("id_token: ", user_id_token.value);
                const api_url = "https://ewuo59e6f7.execute-api.us-west-2.amazonaws.com/dev/docus";
                console.log("api_url: ", api_url);
                const response = await fetch(api_url, {
                    headers: {
                        Authorization: "Bearer " + user_id_token.value
                    }
                }).then(res => res.json())
                //if (!data.ok) {
                //    throw Error("no data available");
                //}
                //doculist.value = await data.json();
                console.log("response..", response);
                console.log("response docus..", response.docus);
                doculist.value = response.docus
                console.log("load returning..", doculist.value);
                console.log('Num docs: ', doculist.value.length)
            }
            catch (err) {
                error.value = err.message;
                console.log(error.value);
            }
            console.log("setup...", doculist.value);
        });
        
        return {
            user_id_token,
            doculist,
            error
        };
        /*
        return {
          doSomethingWithToken: async () => {
            console.log('trying to get docus');
            //const token = await getAccessTokenSilently();
            //console.log(token)
            //const token2 = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Im40aExaQVhjOGJKcWVWQkRXdE5jQSJ9.eyJuaWNrbmFtZSI6ImluZm8iLCJuYW1lIjoiaW5mb0Bnb2xkYm90cy5jb20iLCJwaWN0dXJlIjoiaHR0cHM6Ly9zLmdyYXZhdGFyLmNvbS9hdmF0YXIvZDYzYWRmNDg4Y2E4ZTk3ZjcxNTljNWE2OTBlN2RhOGE_cz00ODAmcj1wZyZkPWh0dHBzJTNBJTJGJTJGY2RuLmF1dGgwLmNvbSUyRmF2YXRhcnMlMkZpbi5wbmciLCJ1cGRhdGVkX2F0IjoiMjAyMi0wNy0xNlQxODo1MTozMy45MDJaIiwiZW1haWwiOiJpbmZvQGdvbGRib3RzLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJpc3MiOiJodHRwczovL2Rldi1nb2xkYm90cy51cy5hdXRoMC5jb20vIiwic3ViIjoiYXV0aDB8NjJiNmExMzQzZWYwNWNmM2I4ZDkzZjQzIiwiYXVkIjoiMzlKWlRMWUJXdnFtNVZ2NWttZlJlbGFRdWpMb3phZmciLCJpYXQiOjE2NTc5OTc0OTMsImV4cCI6MTY1ODAzMzQ5M30.bZwlhCVtKUgYv8ZCd-XqA9XHF_7dOIQdMm-MB53_6bl7na1DnXV30_0xWUt8aMbPrwSonL9CjInTJosEUrkXAF9Ny6oOVHsi-VCdMsY04qqyN-w659syMWvgrjTH-NDNLzGA9dZ_GFXfhif1bDKVtTf2WAkmpVhHZtNID6vSqOTDCjjwREkoIoP9HjpJle7Ahu6iMpFYnVllmA3xMUx-a6nJ69RC_dxaQIStgNnsgk-13v3H_N196vVP_3y6p5e7mPt3yCEW_wQVdFLTvstzY8ncFRETVfQ_13DZmSoSqaYAKjHYS_M2jB7MXG8QIY_V-QDka8BX-vq9nZo7mzdmTw"
            //const api_url = process.env.VUE_APP_API_URL + '/docus';
  
            //const token2 = await getIdTokenClaims();
            //console.log(token2);
            
            const id_token = idTokenClaims._rawValue.__raw;
            console.log('id_token: ', id_token)
            const api_url = "https://ewuo59e6f7.execute-api.us-west-2.amazonaws.com/dev/docus";
            console.log('api_url: ', api_url);
            const response = await fetch(api_url, {
              headers: {
                Authorization: 'Bearer ' + id_token
              }
            });
            const data = await response.json();
            console.log(data);
          },
          user,
          isAuthenticated
        };
            */
    },
}

</script>

<style>

</style>