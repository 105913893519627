<template>
  <p>Login to our</p>
</template>

<script>
  import { useAuth0 } from '@auth0/auth0-vue';

  export default {
    name: 'loginPage',
    setup() {
      const { loginWithRedirect, idTokenClaims } = useAuth0();

      return {
        login: () => {
          loginWithRedirect();
        },
        idTokenClaims
      };
    }
  };
</script>

<style>

</style>

