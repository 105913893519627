import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage'
import LogIn from '../views/auth/LogIn';
//import profilePage from '../components/profilePage.vue'
import Profile from '@/views/Profile.vue'
import docusPage from '../components/docusPage.vue'
//import uploadImage from '../views/uploadImage.vue'
import ImageUpload from '../views/ImageUpload.vue';
//import CreateDocuPage from '../views/CreateDocuPage.vue'
//import ContactPage from '../views/ContactPage.vue'

import { authGuard } from "@auth0/auth0-vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage
    },
    {
        path: '/login',
        name: 'Login',
        component: LogIn
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
      beforeEnter: authGuard
    },
    {
        path: '/docus',
        name: 'Documents',
        component: docusPage,
        beforeEnter: authGuard
    },
    {
        path: '/uploadImageSignedURL',
        name: 'ImageUpload',
        component: ImageUpload,
        beforeEnter: authGuard
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router;
