<template>
  <div>
    <h2>Docus</h2>
    <DocuList />
  </div>

</template>

<script>
  
  import DocuList from './DocuList.vue'

  export default {
    name: "docusPage",
    components: {DocuList},
};
</script>