<template>

  <div class="py-4 container-fluid">
    <div
        class="page-header min-height-300"
        style="
          background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80');
          margin-right: -24px;
          margin-left: -34%;
        "
      >
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.money.title"
              :value="stats.money.value"
              :percentage="stats.money.percentage"
              :iconClass="stats.money.iconClass"
              :iconBackground="stats.money.iconBackground"
              :detail="stats.money.detail"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.users.title"
              :value="stats.users.value"
              :percentage="stats.users.percentage"
              :iconClass="stats.users.iconClass"
              :iconBackground="stats.users.iconBackground"
              :detail="stats.users.detail"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.clients.title"
              :value="stats.clients.value"
              :percentage="stats.clients.percentage"
              :iconClass="stats.clients.iconClass"
              :iconBackground="stats.clients.iconBackground"
              :percentageColor="stats.clients.percentageColor"
              :detail="stats.clients.detail"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.sales.title"
              :value="stats.sales.value"
              :percentage="stats.sales.percentage"
              :iconClass="stats.sales.iconClass"
              :iconBackground="stats.sales.iconBackground"
              :detail="stats.sales.detail"
              directionReverse
            ></card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-5 mb-lg">
            <!-- line chart -->
            <div class="card z-index-2">
              <about-card />
            </div>
          </div>
          <div class="col-lg-7">
            <carousel />
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "./components/Carousel.vue";
import Card from "@/examples/Cards/Card.vue";
import AboutCard from "@/views/components/AboutCard.vue";

import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";
import bkg from "@/assets/img/saturn_bkg_01.png";

export default {
  name: "HomePage",
  data() {
    return {
      backgrounds: {
        home: bkg
      },
      stats: {
        money: {
          title: "",
          value: "Computer Vision",
          percentage: "",
          iconClass: "ni ni-glasses-2",
          detail: "AI powered visual inspection. Off-the-shelf video cameras, paired with our powerful video analysis algorithms make measurement, tracking and anomaly detection a reality.",
          iconBackground: "bg-gradient-primary",
        },
        users: {  
          title: "",
          value: "Document processing",
          percentage: "",
          iconClass: "ni ni-paper-diploma",
          iconBackground: "bg-gradient-danger",
          detail: "AI powered document processing. Extract text, tables and images from complex documents without templates. Analysis, validation and integrity testing. Multi-language handwriting. Human level accuracy.",
        },
        clients: {
          title: "",
          value: "Data collection",
          percentage: "",
          iconClass: "ni ni-tablet-button",
          percentageColor: "text-danger",
          iconBackground: "bg-gradient-success",
          detail: "Mobile data collection. Form design. Capture photos, GPS locations. Collect data offline and sync to our servers when a connection is established.",
        },
        sales: {
          title: "",
          value: "Audio processing",
          percentage: "",
          iconClass: "ni ni-sound-wave",
          iconBackground: "bg-gradient-warning",
          detail: "Multi-language transcription and translation.",
        },
      },
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },
  components: {
    Card,
    AboutCard,
    Carousel
  },
  beforeMount() {
    this.$store.state.showFooter = true;
  },
  beforeUnmount() {
    this.$store.state.showFooter = true;
  }
}
</script>

<style>

</style>