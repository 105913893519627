<template>
  <div>
    <h2>User Profile</h2>

    <pre v-if="isAuthenticated">
        <code>{{ user }}</code>
    </pre>
  </div>
</template>
<script>
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: 'uploadImagePage',
  setup() {
    const { loginWithRedirect, user, isAuthenticated } = useAuth0();

    return {
      login: () => {
        loginWithRedirect();
      },
      user,
      isAuthenticated
    };
  }
};
</script>