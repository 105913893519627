<template>
  
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <div class="content">  
  </div>
  
  <router-view/>
  <app-footer v-show="this.$store.state.showFooter" />
</template>

<script>

// import logoutPage from './components/logoutPage.vue'
// import profilePage from './components/profilePage.vue'
//import docusPage from './components/docusPage.vue'
//import navBar from './components/navBar.vue'
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "App",
  components: {
    //logoutPage,
    //profilePage,
    //docusPage,
    Navbar,
    AppFooter
    //navBar
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
}
</script>

<style>
.content {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
}
</style>
